.login-box {
    background-color: #333!important;
    border-radius: 10px;
    color: white;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.5);
  }
  
  .login-box h2 {
    font-weight: bold;
  }
  
  input::placeholder {
    color: #666;
  }
  
  hr {
    border-color: #666;
  }
  
  a {
    color: #fff;
    text-decoration: none;
  }
  
  a:hover {
    color: #ddd;
  }
  .pt-10{
    padding-top: 100px;
  }