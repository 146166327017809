.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.background-div {
  /* background-image: url('./components//CreatorHub/images/back_drop.webp'); */
  background-image: none;
  background-size: cover; /* Ensures the image covers the entire div */
  background-position: center; /* Centers the image */
  height: 400px; /* Set height to the div */
  width: 100%; /* Full width */
}




.h-60 {
  height: 60px;

  color: white;
  border-bottom: 1px solid rgb(90, 88, 88);
  
}

.custom-padding {
  padding-right: 36px;
  padding-left: 48px;
}

.font-24 {
  font-size: 24px;
  font-weight: 600;
}
.font-20{
  font-size: 16px;
  font-weight: 500;
  font-family: "Builder Sans", Helvetica, Arial, sans-serif;
}

.gap-lg-5 {
  gap: 2rem; /* Adjust according to your design */
}
.gap-0 {
  gap: 0;
}

.gap-3 {
  gap: 25px;
} 

.gap-5 {
  gap: 30px;
}
.gapping{
  gap: 5px;
  font-size: 16px;
}
.w-21{
  width: 36px;
  height: 36px;
}

.color-nav {
  color: rgb(187, 188, 190);
  padding-right: 20px;
  font-weight: 400;
}

.color-nav:hover{
  color: white;
}

.color-learn {
  color:  rgb(187, 188, 190);  /* this is visible */
}
.abs {
  position: absolute;
  top: 170%; /* Position the dropdown just below the parent */
  left: -10%;
  border-radius: 10px;
  font-size: 18px;
  display: none; /* Hidden by default */
  background-color: #fff; /* White background for dropdown */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */
  z-index: 10; /* Ensure it's above other content */
  padding: 10px;
  width: auto; /* Make sure it adjusts to the content width */
  min-width: 90px; /* Optional: Set a minimum width if needed */
  max-width: 150px; /* Optional: Limit the width if necessary */
 padding-left: 15px;
 padding-right: 15px;
 transition: 0.5s;
 font-weight: 400;
 
 
}
.list{
  padding-bottom: 5px;
  padding-top: 5px;
  margin-left: auto;
  margin-right: auto;
  
}
.learn-bg:hover{
  background-color: #2c2c31;
  height: 100%;
  border-radius: 10px;
  transition: 0.5s;
}

.nav-item:hover .abs {
  display: block;
  background-color: #2c2c31; /* Show the dropdown on hover */
  transition: 0.5s;
}

.icon-1{
  position: absolute;
  right: 10%;
  top: 0%;
}


.dropdown-menu {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown-menu li a {
  color: #111216; /* Dark color for visibility */
  padding: 8px 12px;
  display: block;
  text-decoration: none;
  font-size: 16px; /* Adjust font size if needed */
  width: 100%; /* Ensure the anchor takes the full width of the dropdown */
}

.dropdown-menu li a:hover {
  background-color: #f0f0f0; /* Optional hover effect */
}
.login{
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  font-weight: 400;
  
}
.login:hover{
  border-radius: 10px;
  background: #2e2e2e;
}


/* aside style */
.aside {
  width: 33%;
  height: 100vh;
  overflow: hidden;
  border-right: 1px solid rgb(90, 88, 88);
}

@media (max-width: 768px) {
  .aside {
    width: 100%; /* Full width for smaller screens */
  }
  
.font-24 {
  font-size: 15px;
  font-weight: 600;
}

}

@media (min-width: 768px) {
  .aside {
    width: 40%; /* 40% width for screen size 768px and above */
  }
}

.h-60-aside {
  height: 60px;
  background-color: #111216;  
  color: white;

  
}

.aside-logo{
  width: 20px;
  height: 20px;
}

.aside-padding{
  padding-left: 7%;
  padding-right: 7%;
}
.close{
  width: 20px;
  height: 20px;
}

.aside-nav-texts{
  background-color: #2c2c31;
  padding-top: 30px;
}
.inner-aside{
  padding-bottom: 25px;
  
}
.aside-text{
color: white;
padding-left: 8%;
padding-right: 8%;
font-weight: 500;
}

.font-24-aside {
  font-size: 20px;
  font-weight: 600;
}

/* Background Image */
.background {
  /* background-image: url('./components//CreatorHub//images//back_drop.webp'); */
  background-image: none;
  background-size: cover;
  background-position: bottom;
  height: 90vh;
  width: 100%;
  overflow: hidden;
}
.background2 {
  /* background-image: url('./components//Auth//images//bg.jpg'), url('./components/Auth//images/transparent.png'); */
  background-image: none;
  background-size: cover, cover; /* Make both images cover the div */
  background-position: center, center; /* Center both images */
  min-height: 90vh; /* Full viewport height */
  display: flex; /* Flexbox for centering content */
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  position: relative; /* For correct child element positioning */
  overflow: auto;
}
body {
  background-color: #1c1c1c!important; /* Dark background similar to the image */
  overflow: auto;
}
/* .custom-toast {
  margin-top: 100px;
} */

.overlay {
  background-color: rgba(0, 0, 0, 0.5); /* Black overlay */
  height: 150vh;
  top: 0;
  left: 0;
  z-index: 0;
}


@media (min-width: 425px) {
  .overlay {
    height: 240vh; /* Height when screen width is 1400px or more */
  }
  
}

@media (max-width: 786px) {
  
  .roblox-logo-image{
    width: 100px;
   }
}
@media (max-width: 506px) {
  
  .w-35{
    width: 300px;
  }
  
  
}
@media (min-width: 800px) {
  .background2{
    overflow: hidden;
  } 
}
@media (min-width: 560px) {
  .top-40{
    top: 40%;
  }
}
@media (min-width: 768px) {
  .overlay {
    height: 240vh; /* Height when screen width is 1400px or more */
  }
  
  
}
@media (min-width: 1024px) {
  .overlay {
    height: 150vh; /* Height when screen width is 1400px or more */
  }
  body {
    background-color: #1c1c1c!important; /* Dark background similar to the image */
    overflow: hidden;
  }
}

@media (min-width: 1400px) {
  .overlay {
    height: 180vh; /* Height when screen width is 1400px or more */
  }
}

@media (min-width: 1700px) {
  .overlay {
    height: 120vh; /* Height when screen width is 1400px or more */
  }
}




.content {
  z-index: 2;
  padding-top: 20px; /* Adds some spacing from the top */
}

h1 {
  color: #fff; /* White text */
}

/* creator-hub CSS */
.custom-font-h1{
  font-size: 40px;
  font-weight: 600;
}
.start-creating{
  font-size: 20px;
  font-weight: 600;
  border-radius: 10px;
  padding: 5px 50px 10px 50px;
  margin-top: 50px;
}


.align-items-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.roblox-logo-image{
 height: 55px;
}

.ps-8{
  padding-right: 20px;
}
.login-roblox{
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 45px;
  padding-right: 45px;
  background-color: white;
  color: black;
  font-size: 16px;
}

.w-35{
  background-color: #232527;
  padding: 20px; /* Ensure there's padding inside the form */
  z-index: 2;
  color: white;
  margin-top: 120px;
  width: 280px;
  margin-left: auto;
  margin-right: auto;
}
.birthday-select {
  z-index: 3; /* Higher than the overlay */
  position: relative; /* Ensures z-index works */
  width: 430px;
}
.sign-in-text{
  font-size: 20px;
  font-weight: 700;
 text-transform: uppercase;
 
}
.bg-bk{
  padding-top: 5px;
  padding-bottom: 5px;
  color:gray
}
.w-40{
  width: 40%;
}
.w-30{
  width: 30%;
}
.fs-7{
  font-size: 15px;
  font-weight: 300;
}
.line{
  text-decoration-line: underline;
  
}
.custom-col {
  flex: 0 0 14.28%; /* 100% / 7 = 14.28% */
  max-width: 14.28%;
}
.grid{
  display: flex;
  gap: 10px;
  padding-left: 40px;
  padding-right: 40px;
}
.h-09{
  height: 50px;
  width:100px
}
footer{
  width: 100vw;
  z-index: 20;
}
.border-botom{
  border-bottom: 1px solid gray;
}
.fs-12{
  font-size: 15px;
}

/* users page */
.user-img{
  width: 50px;
  cursor: pointer;
}
.icon-2{
  position: absolute;
  right: -20%;
  top: 32%;
  cursor: pointer;
}

.abs1 {
  position: absolute;
  top: 170%; /* Position the dropdown just below the parent */
  left: 0%;
  border-radius: 10px;
  font-size: 18px;
  display: none; /* Hidden by default */
  background-color: #fff; /* White background for dropdown */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow */
  z-index: 10; /* Ensure it's above other content */
  padding: 10px;
  width: auto; /* Make sure it adjusts to the content width */
  min-width: 90px; /* Optional: Set a minimum width if needed */
  max-width: 150px; /* Optional: Limit the width if necessary */
 padding-left: 15px;
 padding-right: 15px;
 transition: 0.5s;
 font-weight: 400;
 
 
}
.user-dropdown{
  background-color: #2c2c31;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  top: 100%; /* Already there */
  right: 0%;
  /* Aligns dropdown to the right */
  z-index: 1000; /* Ensures it's on top of other elements */
  min-width: 250px; /* Set a minimum width */
  max-width: 280px; /* Set a max width if needed */
  overflow: hidden;} /* 
}
.list-color-user{
  color: #a1a1a4;
  font-weight: 600;
  width: 100%;
}
.list-color-user:hover{
  background: #8e8c8c;
  color: white;
}
.toggle{
  display: none;
}
.toggle:hover{
  display: grid;
}


/* main content */
.list-color-user{
  color: #a1a1a4;
  font-weight: 600;
  width: 100%;
}
.list-color-user:hover{
  background: #8e8c8c;
  color: white;
}

.arrow{
  right: 10%;
}
.fs-s{
  font-size: 15px;
  padding-left: 5px;
  padding-right: 5px;
}
.fs-xs{
  font-size: 15px;
}
.view-all{
  top: -20%;
  left: 3%;
  color: rgb(168, 160, 160);
  font-weight: 500;
}
.br-r{
  border-right: 1px solid rgb(149, 146, 146);
  min-height: 100vh
}
.border-b{
  border-bottom: 1px solid rgb(149, 146, 146);
}
.user-side:hover{
  background-color: #78787a;
}
.pointer{
  cursor: pointer;
}
.bg-light-gray{
  background-color: rgb(68, 65, 65);
}
.w-10{
  width: 15%;
}
.w-15{
  width: 25%;
}
.h-70{
overflow: hidden;
}
.p-7{padding-left: 10px;}
.z-index{
  z-index: 9999;
}
.w-150{
  width: 250%;
}

.grid-row {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 4 columns on small screens */
  gap: 10px; /* Optional: add space between images */
}


/* responsiveness style */
@media (min-width: 768px) {
  .custom-font-h1{
    font-size: 78px;
    font-weight: 600;
  }
  .start-creating{
    font-size: 20px;
    font-weight: 600;
    border-radius: 10px;
    padding: 5px 110px 10px 110px;
    margin-top: 50px;
  }
  .login-roblox{
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 45px;
    padding-right: 45px;
    background-color: white;
    color: black;
    font-size: 16px;
  }
  .w-35{
    background-color: #232527;
    padding: 20px; /* Ensure there's padding inside the form */
    z-index: 2;
    color: white;
    margin-top: 90px;
    width: 500px;
    margin-left: auto;
    margin-right: auto;
  }
  .grid-row {
    grid-template-columns: repeat(7, 1fr); /* 7 columns for md and up */
  }
  .w-10{
    width:10%;
  }
}



.file-icon {
  text-align: center;
  margin: 20px auto;
}

.flexC{
  display: flex ;
  justify-content: center;
  align-items: center;
}
.flexS{
  display: flex ;
  justify-content: start;
  align-items: center;
}
.flexE{
  display: flex ;
  justify-content: end;
  align-items: center;
}
.ms-auto{
  margin-left: auto;
  margin-right: auto;
  
}
.w-100vw{
  width: 65vw;
}
.active-tab {
  background-color:  #007bff; /* Example active background color */
  color: white; /* Active text color */
}

.pointer {
  cursor: pointer;
}
.bottom{
  bottom: 10px;
}

.table-responsive {
   /* Set a max height for the scrollable area */
  width: 80%;
  border: 2px solid red;
}

.delete-button {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 1;
  background-color: transparent;
  border: none;
  color: red;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 0px, 2px;
}

.delete-button:hover {
  color: darkred;
}