@font-face {
  font-family: 'Builder Sans';
  src: url('./assets/fonts/BuilderSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Builder Sans';
  src: url('./assets/fonts/BuilderSans-Medium.woff') format('woff');
  font-weight: 500; /* Medium weight */
  font-style: normal;
}

@font-face {
  font-family: 'Builder Sans';
  src: url('./assets/fonts/BuilderSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Builder Sans';
  src: url('./assets/fonts/BuilderSans-ExtraBold.woff') format('woff');
  font-weight: 800; /* Extra bold weight */
  font-style: normal;
}

body {
  font-family: 'Builder Sans', Helvetica, Arial, sans-serif;
}
